<template>
  <FeedHeaderWrapper>
    <FeedHeaderStyled>
      <div class="filterWrapper" @click="toggleFilterTray()">
        <TalpaIcon class="filterIcon" :scope="'Feed'" :name="'FilterIcon'" />
      </div>
      <CategoryWrapperStyled>
        <CategoryListStyled v-for="category in categories" :key="category.id" @click="selectedCategories(category)">
          <CategoryStyled :class="{ selected: category.isSelected }">
            <TalpaIcon class="icon" :scope="'Feed'" :name="`${category.iconName}`" /> {{ $t('feed.category.' + category.label) }}
          </CategoryStyled>
        </CategoryListStyled>
        <CustomSingleSelect
          class="categorySelect"
          :selectedOption="selectedOption"
          @selectedFilter="selectedCategories"
          :options="categories"
          :shouldNotResetSelectedOption="true"
          :customOptions="'feed.category.'"
          :customLabels="'feed.category.'"
          :categoryIcon="true"
          :closeOnSelect="true"
        >
        </CustomSingleSelect>
      </CategoryWrapperStyled>
    </FeedHeaderStyled>
    <transition name="fade">
      <FilterTrayStyled v-if="isTrayExpanded">
        <FilterStyled>
          <span>{{ $t('feed.filter.title') }}:</span>
          <CustomSingleSelect
            class="categorySelect"
            :selectedOption="selectedTimeFilterOption"
            @selectedFilter="selectTimeFilter"
            :options="filterOptions"
            :shouldNotResetSelectedOption="true"
            :customOptions="'feed.filter.options.'"
            :customLabels="'feed.filter.label.'"
            :closeOnSelect="true"
          />
          <span v-if="subsidiaryMembershipOptions.length > 0">{{ $t('feed.subsidiaries') }}: </span>
          <CustomSingleSelect
            class="categorySelect"
            v-if="subsidiaryMembershipOptions.length > 0"
            :selectedOption="selectedOptionTranslated"
            @selectedFilter="selectSubsidiaryFilter"
            :options="subsidiaryMembershipOptions"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
          />
        </FilterStyled>
        <ClearStyled>
          <span class="reset" @click="resetFilter()">{{ $t('feed.filter.clear') }}</span> |
          <XIcon size="1.5x" @click="toggleFilterTray()" />
        </ClearStyled>
      </FilterTrayStyled>
    </transition>
  </FeedHeaderWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { XIcon } from 'vue-feather-icons'
import get from 'lodash/get'

import { TalpaIcon, CustomSingleSelect } from '@common/components'
import { flexStartCenter, flexCenter } from '@styles/mixins'

const FeedHeaderWrapper = styled('div')`
  position: sticky;
  top: 0;
  padding-top: 0.5rem;
  background: ${p => p.theme.colors.mainBG};
  z-index: 100;
`

const FeedHeaderStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  top: 0;
  transition: background-color 0.25s ease;
  font-size: 16px;
  border-radius: 10px 10px 0 0;
  justify-content: flex-start;
  padding: 1rem 1rem 0.7rem 1rem;
  background: ${p => p.theme.colors.solidBG};
  .filterWrapper {
    @media (max-width: 798px) {
      ${flexStartCenter}
    }
  }

  .filterIcon {
    border: ${p => p.theme.colors.atomic.filterButton} 10px solid;
    border-radius: 5px;
    width: 15px;
    height: 17px;
    cursor: pointer;
    background: ${p => p.theme.colors.atomic.filterButton};
    > svg {
      width: 15px;
      height: 17px;
      background: ${p => p.theme.colors.atomic.filterButton};

      & > path:nth-of-type(1) {
        fill: ${({ theme }) => theme.colors.primary};
      }
      &:hover {
        & > path:nth-of-type(1) {
          fill: ${({ theme }) => theme.colors.primaryActive};
        }
      }
    }
  }
`
const CategoryWrapperStyled = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .categorySelect {
    display: flex;
    .feed_multiselect {
      .multiselect__content-wrapper {
        width: 12rem;
      }
    }
    .multiselect__option {
      display: flex;
      justify-content: flex-start;
      .icon {
        margin-right: 1rem;
      }
      > svg {
        margin-left: 1rem;
      }
    }
    @media (min-width: 798px) {
      display: none;
    }
  }
`

const CategoryListStyled = styled('div')`
  display: none;
  padding: 0.3rem;
  @media (min-width: 798px) {
    ${flexCenter}
  }
`

const CategoryStyled = styled('div')`
  display: flex;
  font-size: 14px;
  cursor: pointer;
  gap: 0.3rem;
  .icon {
    width: 20px !important;
    height: 20px !important;
  }
  &:hover {
    color: ${props => props.theme.colors.primaryActive};
  }
  &.selected {
    color: ${props => props.theme.colors.primary};
    svg {
      & > path:nth-of-type(1) {
        fill: ${({ theme }) => theme.colors.primary};
      }
      & > path:nth-of-type(2) {
        fill: ${({ theme }) => theme.colors.primary};
      }
      & > path:nth-of-type(3) {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`
const FilterTrayStyled = styled('div')`
  background: ${p => p.theme.colors.solidBG};
  height: 2rem;
  margin-top: 0.2rem;
  padding: 0.25rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
`
const FilterStyled = styled('div')`
  display: flex;
  align-items: center;
  .categorySelect {
    .multiselect__content-wrapper {
      width: 12rem;
    }
    .multiselect__single {
      max-width: 12rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`

const ClearStyled = styled('div')`
  ${flexStartCenter}
  gap: 1rem;
  > svg {
    margin-top: 0.1rem;
    stroke: ${p => p.theme.colors.primary};
    cursor: pointer;
  }
  .reset {
    cursor: pointer;
    &:hover {
      color: ${p => p.theme.colors.primary};
    }
  }
`

export default {
  props: {
    subsidiaryMemberships: {
      type: Array,
      required: true,
    },
  },
  components: {
    FeedHeaderWrapper,
    FeedHeaderStyled,
    TalpaIcon,
    CategoryListStyled,
    CategoryStyled,
    FilterTrayStyled,
    XIcon,
    FilterStyled,
    ClearStyled,
    CustomSingleSelect,
    CategoryWrapperStyled,
  },
  data() {
    return {
      categories: [
        {
          id: 'ALL',
          iconName: 'all',
          label: 'all',
        },
        {
          id: 'PRODUCTION',
          iconName: 'production',
          label: 'production',
        },
        {
          id: 'MAINTENANCE',
          iconName: 'maintenance',
          label: 'maintenance',
        },
        {
          id: 'SAFETY',
          iconName: 'safety',
          label: 'safety',
        },
        {
          id: 'HARDWARE',
          iconName: 'hardware',
          label: 'hardware',
        },
        {
          id: 'INFORMATION',
          iconName: 'information',
          label: 'information',
        },
      ],
      filterOptions: [
        {
          id: 'anytime',
          label: 'anytime',
        },
        {
          id: 'lastday',
          label: 'lastday',
        },
        {
          id: 'lastweek',
          label: 'lastweek',
        },
        {
          id: 'lastmonth',
          label: 'lastmonth',
        },
        {
          id: 'lastyear',
          label: 'lastyear',
        },
      ],
      selectedTimeFilterOption: {
        id: 'anytime',
        label: 'anytime',
      },
      selectedOption: {},
      isTrayExpanded: false,
      selectedSubsidiaryFilterOption: null,
    }
  },
  created() {
    const preselectedCategory = this.categories.find(({ id }) => id === 'ALL')
    this.selectedCategories(preselectedCategory)
    if (!this.selectedSubsidiaryFilterOption) {
      if (this.subsidiaryMembershipOptions.length > 1) {
        this.selectedSubsidiaryFilterOption = this.subsidiaryMembershipOptions.find(({ id }) => id === 'ALL')
      }
    }
  },
  computed: {
    selectedOptionTranslated() {
      if (this.selectedSubsidiaryFilterOption?.id === 'ALL') {
        return {
          ...this.selectedSubsidiaryFilterOption,
          label: this.$t('feed.category.all'),
        }
      }
      return this.selectedSubsidiaryFilterOption
    },
    subsidiaryMembershipOptions() {
      if (this.subsidiaryMemberships.length > 1) {
        return [{ id: 'ALL', label: this.$t('feed.category.all') }, ...this.subsidiaryMemberships]
      }
      return this.subsidiaryMemberships
    },
  },
  methods: {
    toggleFilterTray() {
      this.isTrayExpanded = !this.isTrayExpanded
    },
    selectedCategories(selectedCategory) {
      this.selectedOption = selectedCategory
      this.categories = this.categories.map(cat => ({
        ...cat,
        isSelected: cat.id === get(selectedCategory, 'id', ''),
      }))

      this.$emit('handleCategoryFilterChange', selectedCategory.id)
    },
    selectTimeFilter(timeFilter) {
      this.selectedTimeFilterOption = timeFilter
      this.$emit('handleTimeFilterChange', timeFilter.id)
    },
    resetFilter() {
      this.selectedTimeFilterOption = this.filterOptions[0]
      this.selectedSubsidiaryFilterOption = this.subsidiaryMembershipOptions.length > 1 ? this.subsidiaryMembershipOptions[0] : null
      this.$emit('handleTimeFilterChange', this.selectedTimeFilterOption.id)
      this.$emit('handleSubsidiaryFilterChange', null)
    },
    selectSubsidiaryFilter(subsidiaryFilter) {
      this.selectedSubsidiaryFilterOption = this.subsidiaryMembershipOptions.find(({ id }) => id === subsidiaryFilter.id)
      this.$emit('handleSubsidiaryFilterChange', subsidiaryFilter.id)
    },
  },
}
</script>
