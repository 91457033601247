<template>
  <AddCardStyled>
    <addCardIcon size="2.5x" />
  </AddCardStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import addCardIcon from '@/assets/icons/actions/addCardIcon.svg'

const AddCardStyled = styled('div')`
  ${flexCenter}
  flex-direction: column;
  width: calc(15rem - 2px);
  height: calc(15rem - 2px);
  box-sizing: border-box;
  border: 1px dashed ${({ theme }) => theme.colors.title};
  border-radius: 9px;
  color: ${({ theme }) => theme.colors.title};
`

export default {
  components: {
    AddCardStyled,
    addCardIcon,
  },
}
</script>
