<template>
  <MachineAssignmentFeedItemMoleculeStyled>
    <TextWrapper>
      <div class="event-created">{{ eventTimeFormatted }}</div>
      <div>
        <div>
          <HighlightedText v-if="assignedAsset">
            <div class="event-data">{{ $t('MachineAssignmentEvents.titles.banner') }}</div>
            <span> {{ assignedAsset.type?.name }}, {{ assignedAsset.name }} </span>
            <span v-if="assignedAsset.oem"> {{ $t('MachineAssignmentEvents.titles.of') }} {{ assignedAsset.oem?.name }} </span>
            <span v-if="assignedAsset.site"> {{ $t('MachineAssignmentEvents.titles.in') }} {{ assignedAsset.site?.name }} </span>
          </HighlightedText>
          <div v-else>
            {{ $t('MachineAssignmentEvents.titles.bannerNoData') }}
          </div>
        </div>
      </div>
      <div class="link">
        <router-link :to="{ name: 'AssetOverview', params: { id: assignedAsset.id } }">
          <div class="link-text"><ArrowRightIcon />{{ $tc('actions.moreDetails') }}</div>
        </router-link>
      </div>
    </TextWrapper>
    <IconStyled>
      <TalpaIcon v-if="iconName" class="asset-icon" :scope="'AssetTypes'" :name="iconName" />
    </IconStyled>
  </MachineAssignmentFeedItemMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { ObserveVisibility } from 'vue-observe-visibility'
import ArrowRightIcon from '@common/components/src/TalpaIcons/Misc/ArrowRightIcon.svg'
import { TalpaIcon } from '@common/components'

const MachineAssignmentFeedItemMoleculeStyled = styled('div')`
  background-color: ${({ theme }) => theme.colors.atomic.feedList};
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 6px #00000026;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`
const TextWrapper = styled('div')`
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.atomic.textSecondary};

  > div {
    padding: 4px 0px;
  }
  .link {
    padding-top: 16px;
    .link-text {
      display: flex;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }
    svg {
      padding-right: 4px;
      path {
        stroke: ${({ theme }) => theme.colors.atomic.primary};
      }
    }
  }
  .event-data {
    padding: 4px 0px;
  }
  .event-created::first-letter {
    text-transform: capitalize;
  }
`

const HighlightedText = styled('span')`
  > div {
    font-weight: 600;
    font-size: 20px;
  }
`

const IconStyled = styled('div')`
  display: flex;
  align-items: center;
  @media (max-width: 425px) {
    display: none;
  }
  .asset-icon {
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg.icon {
      width: max-content;
      height: max-content;
      padding: 10px;
    }
  }
`

export default {
  inject: ['uiSettings'],
  components: {
    MachineAssignmentFeedItemMoleculeStyled,
    TextWrapper,
    HighlightedText,
    ArrowRightIcon,
    IconStyled,
    TalpaIcon,
  },
  directives: {
    'observe-visibility': ObserveVisibility,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locale() {
      const ds = this.uiSettings?.dates || 'DE_DE'
      return ds.replace('_', '-').toLowerCase()
    },
    eventTimeFormatted() {
      const dt = DateTime.fromISO(this.event?.createdAt)
      if (dt.diffNow().as('days') >= -3) {
        return `${dt.setLocale(this.locale).toRelativeCalendar()} : ${dt
          .setLocale(this.locale)
          .toLocaleString({ ...DateTime.TIME_SIMPLE })}`
      }
      return dt.setLocale(this.locale).toLocaleString({ ...DateTime.DATETIME_SHORT })
    },
    assignedAsset() {
      return this.event.assets[0]
    },
    iconName() {
      return this.assignedAsset?.type?.name.split(' ').join('')
    },
  },
}
</script>
