<template>
  <HomeStyled>
    <WelcomeTemplate></WelcomeTemplate>
    <PerformanceTemplate></PerformanceTemplate>
    <SidebarTemplate></SidebarTemplate>
    <FeedOrganism></FeedOrganism>
  </HomeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import WelcomeTemplate from './Home/WelcomeTemplate.vue'
import PerformanceTemplate from './Home/PerformanceTemplate.vue'
import SidebarTemplate from './Home/SidebarTemplate.vue'
import FeedOrganism from './Home/FeedOrganism.vue'

const HomeStyled = styled('div')`
  position: relative;
  min-height: calc(100% - 1rem);
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 1fr;
  grid-gap: 0.5rem;
  grid-template-areas:
    'welcome'
    'performance'
    'feed';

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 16.5rem;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas:
      'welcome sidebar'
      'performance sidebar'
      'feed sidebar';
  }
`

export default {
  components: {
    HomeStyled,
    WelcomeTemplate,
    PerformanceTemplate,
    SidebarTemplate,
    FeedOrganism,
  },
}
</script>
