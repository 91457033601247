<template>
  <FeedOrganizationStyled>
    <FeedHeaderMolecule
      @handleCategoryFilterChange="handleCategoryFilterChange"
      @handleTimeFilterChange="handleTimeFilterChange"
      @handleSubsidiaryFilterChange="handleSubsidiaryFilterChange"
      :subsidiaryMemberships="subsidiaryMemberships"
      v-if="!$apollo.queries.myMemberships.loading"
    />
    <TalpaLoaderWrapper v-if="$apollo.loading" />
    <template v-else>
      <StayTunedInfoCard class="infoCard" v-if="myEventsFiltered.length === 0" />
      <template v-else>
        <EventListStyled>
          <template v-for="event in myEventsFiltered">
            <FeedShiftReportEventMolecule v-if="event.type === 'SHIFT_REPORT'" :key="event.id" :event="event" />
            <MachineAssignmentFeedItemMolecule v-if="event.type === 'ASSET_ASSIGNMENT'" :key="event.id" :event="event" />
            <PerformanceGoalFeedItem
              v-if="event.type === 'PERFORMANCE_GOAL_RESULT' && hasPlanningAppPermission"
              :key="event.id"
              :event="event"
            />
          </template>
        </EventListStyled>
        <InfiniteLoading :identifier="infiniteId" @infinite="infiniteHandler">
          <template slot="no-results">
            <FeedListNoMoreMessage @handleGoUp="handleScrollToTop" />
          </template>
          <template slot="no-more">
            <FeedListNoMoreMessage @handleGoUp="handleScrollToTop" />
          </template>
        </InfiniteLoading>
      </template>
    </template>
  </FeedOrganizationStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import InfiniteLoading from 'vue-infinite-loading'
import { DateTime } from 'luxon'

import permissionsMixin from '@/mixins/permissions'

import { TalpaLoaderWrapper } from '@common/components'
import { PerformanceGoalFeedItem } from '@common/components'

import StayTunedInfoCard from '@/components/Atomic/Organisms/StayTunedInfoCard'
import FeedListNoMoreMessage from '@/components/Atomic/Molecules/FeedListNoMoreMessage'

import FeedHeaderMolecule from './FeedOrganism/FeedHeaderMolecule'
import FeedShiftReportEventMolecule from './FeedOrganism/FeedShiftReportEventMolecule'
import MachineAssignmentFeedItemMolecule from './FeedOrganism/MachineAssignmentFeedItemMolecule'

import MY_EVENTS_QUERY from '#/graphql/feed/myEvents.gql'
import MY_SUBSIDIARIES_QUERY from '#/graphql/feed/mySubsidiaryMemberships.gql'

const FeedOrganizationStyled = styled('div')`
  grid-area: feed;
  display: grid;
  max-width: 100%;
  grid-gap: 0.25rem;
  grid-template-columns: auto;
  grid-template-rows: min-content auto;
  background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.7).css()};
  transition: background-color 0.25s ease;
  border-radius: 10px;
  grid-template-areas:
    'header'
    'list';
  .infoCard {
    align-self: center;
  }
`

const EventListStyled = styled('div')`
  justify-self: center;
  display: grid;
  width: 100%;
  margin: 1rem;
  max-width: 800px;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  grid-gap: 2rem;
`

const PAGE_SIZE = 5
const timeFilterResolveMap = {
  anytime: () => undefined,
  lastday: () => ({
    gte: DateTime.now().minus({ days: 1 }).toUTC().toISO(),
  }),
  lastweek: () => ({
    gte: DateTime.now().minus({ weeks: 1 }).toUTC().toISO(),
  }),
  lastmonth: () => ({
    gte: DateTime.now().minus({ months: 1 }).toUTC().toISO(),
  }),
  lastyear: () => ({
    gte: DateTime.now().minus({ years: 1 }).toUTC().toISO(),
  }),
}

export default {
  mixins: [permissionsMixin],
  components: {
    FeedOrganizationStyled,
    FeedHeaderMolecule,
    FeedShiftReportEventMolecule,
    MachineAssignmentFeedItemMolecule,
    PerformanceGoalFeedItem,
    InfiniteLoading,
    EventListStyled,
    FeedListNoMoreMessage,
    StayTunedInfoCard,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      myEvents: [],
      infiniteId: +new Date(),
      initialLoading: false,
      hasNextPage: true,
      lastCursor: undefined,
      createdAtFilter: undefined,
      recipientId: {},
      fetched: 0,
      categoriesFilter: ['PRODUCTION', 'MAINTENANCE', 'SAFETY', 'HARDWARE', 'INFORMATION'],
      myMemberships: [],
    }
  },
  computed: {
    subsidiaryMemberships() {
      const memberships = this.myMemberships
        .filter(x => x.__typename === 'SubsidiaryMembership')
        .map(({ subsidiary }) => {
          return {
            id: subsidiary.id,
            label: subsidiary.name,
          }
        })
      return memberships
    },
    myEventsFiltered() {
      return this.myEvents.filter(event => {
        if (event.type === 'ASSET_ASSIGNMENT') {
          return event.assets[0] !== null
        }
        return true
      })
    },
  },
  methods: {
    handleScrollToTop() {
      this.$emit('scrollToTop')
    },
    handleCategoryFilterChange(categoryId) {
      if (
        !(
          (this.categoriesFilter.length > 1 && categoryId === 'ALL') ||
          (this.categoriesFilter.length === 1 && this.categoriesFilter.includes(categoryId))
        )
      ) {
        if (categoryId === 'ALL') {
          this.categoriesFilter = ['PRODUCTION', 'MAINTENANCE', 'SAFETY', 'HARDWARE', 'INFORMATION']
        } else {
          this.categoriesFilter = [categoryId]
        }
      }
    },
    handleTimeFilterChange(timeFilterId) {
      this.createdAtFilter = timeFilterResolveMap[timeFilterId]()
    },
    handleSubsidiaryFilterChange(subsidiaryFilter) {
      if (subsidiaryFilter === null || subsidiaryFilter === 'ALL') {
        this.recipientId = {}
      } else {
        this.recipientId = {
          in: [subsidiaryFilter],
        }
      }
    },
    async infiniteHandler($state) {
      // TODO: check if this is needed in certain cases
      // if (this.myEvents.length < PAGE_SIZE) {
      //   $state.complete()
      //   return
      // }
      try {
        const lastEvent = this.myEvents[this.myEvents.length - 1]
        const additionalEventsQuery = await this.$apollo.query({
          query: MY_EVENTS_QUERY,
          variables: {
            take: PAGE_SIZE,
            skip: 1,
            cursor: {
              eventTime_id: {
                id: lastEvent.id,
                eventTime: lastEvent.createdAt,
              },
            },
            where: {
              eventCategory: {
                in: this.categoriesFilter,
              },
              recipientId: this.recipientId,
              createdAt: this.createdAtFilter,
            },
          },
        })
        const additionalEvents = additionalEventsQuery?.data?.myEvents
        this.myEvents = [...this.myEvents, ...additionalEvents]
        if (additionalEvents.length < PAGE_SIZE) {
          $state.complete()
        } else {
          $state.loaded()
        }
      } catch (err) {
        $state.error()
      }
    },
  },
  apollo: {
    myEvents: {
      query: MY_EVENTS_QUERY,
      variables() {
        return {
          take: PAGE_SIZE,
          where: {
            eventCategory: {
              in: this.categoriesFilter,
            },
            recipientId: this.recipientId,
            createdAt: this.createdAtFilter,
          },
        }
      },
    },
    myMemberships: {
      query: MY_SUBSIDIARIES_QUERY,
    },
  },
}
</script>
