<template>
  <Home />
</template>

<script>
import permissionsMixin from '@/mixins/permissions'
import Home from '../../pages/Home.vue'

export default {
  mixins: [permissionsMixin],
  components: {
    Home,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
