<template>
  <UpIconWrapper>
    <ChevronUpIcon />
  </UpIconWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronUpIcon as ChevronUpIconSrc } from 'vue-feather-icons'

const UpIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.atomic.primary};
  margin-bottom: 10px;
  @media (min-width: 768px) {
    width: 35px;
    height: 35px;
  }
`
const ChevronUpIcon = styled(ChevronUpIconSrc)`
  color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.black : theme.colors.atomic.white)};
`
export default {
  components: {
    UpIconWrapper,
    ChevronUpIcon,
  },
}
</script>
