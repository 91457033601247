<template>
  <CardAreaHeaderStyled>
    <LeftPanelStyled>
      <div class="header">{{ $t('feed.card.title') }}</div>
      <div class="card">{{ feedCards.length }}/5 {{ $t('feed.card.cards') }}</div>
    </LeftPanelStyled>
    <RightPanelStyled>
      <CardTimePicker />
      <AssetDimensionPicker />
    </RightPanelStyled>
  </CardAreaHeaderStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'
import CardTimePicker from './Header/CardTimePicker'
import AssetDimensionPicker from './Header/AssetDimensionPicker'

const CardAreaHeaderStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 10px;
`
const LeftPanelStyled = styled('div')`
  ${flexCenter}
  .header {
    font-size: 16px;
    margin-right: 1rem;
  }
  .card {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.atomic.subTitle};
  }
`
const RightPanelStyled = styled('div')`
  ${flexCenter}
`

export default {
  props: {
    feedCards: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardAreaHeaderStyled,
    LeftPanelStyled,
    RightPanelStyled,
    CardTimePicker,
    AssetDimensionPicker,
  },
}
</script>
