<template>
  <SidebarTemplateStyled>
    <SidebarDashboards />
    <SidebarAssets />
  </SidebarTemplateStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SidebarDashboards from './SidebarTemplate/DashboardsSidebarOrganism.vue'
import SidebarAssets from './SidebarTemplate/AssetsSidebarOrganism.vue'

const SidebarTemplateStyled = styled('div')`
  display: none;
  grid-area: sidebar;
  @media (min-width: 1200px) {
    position: sticky;
    top: 0.5rem;
    height: calc(100vh - 52px - 1rem);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 20rem 1fr;
    grid-gap: 0.5rem;
  }
`

export default {
  components: {
    SidebarTemplateStyled,
    SidebarDashboards,
    SidebarAssets,
  },
}
</script>
