<template>
  <FeedShiftReportEventMoleculeStyled v-observe-visibility="visibilityChanged">
    <TextWrapper>
      <div class="event-created">{{ eventTimeFormatted }}</div>
      <div class="event-data">
        <div>
          <HighlightedText>{{ event.shiftName + ' ' + $t('feed.insightsAvailable') }}</HighlightedText>
        </div>
        <div>{{ event.recipient.name }}</div>
        <div>{{ shiftTime }}</div>
      </div>
      <div class="link">
        <router-link :to="{ name: 'ShiftInsights', params: { id: event.id } }">
          <div class="link-text"><ArrowRightIcon />{{ $tc('actions.moreDetails') }}</div>
        </router-link>
      </div>
    </TextWrapper>
    <ChartWrapper>
      <ShiftHealthStatus :eventId="event.id" :shouldLoad="wasInViewport" />
    </ChartWrapper>
  </FeedShiftReportEventMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime } from 'luxon'
import { ObserveVisibility } from 'vue-observe-visibility'

import ArrowRightIcon from '@common/components/src/TalpaIcons/Misc/ArrowRightIcon.svg'

import ShiftHealthStatus from './FeedShiftReportEventMolecule/ShiftHealthStatusMolecule.vue'

const FeedShiftReportEventMoleculeStyled = styled('div')`
  background-color: ${({ theme }) => theme.colors.atomic.feedList};
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 3px 6px #00000026;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`

const ChartWrapper = styled('div')`
  grid-area: chart;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 425px) {
    display: none;
  }
`

const TextWrapper = styled('div')`
  grid-area: text;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.atomic.textSecondary};

  > div {
    padding: 4px 0px;
  }
  .link {
    padding-top: 16px;
    .link-text {
      display: flex;
      height: 24px;
      line-height: 24px;
      text-align: center;
    }
    svg {
      padding-right: 4px;
      path {
        stroke: ${({ theme }) => theme.colors.atomic.primary};
      }
    }
  }
  .event-data {
    > div {
      padding: 4px 0px;
    }
  }
  .event-created::first-letter {
    text-transform: capitalize;
  }
`

const HighlightedText = styled('span')`
  font-weight: 600;
  font-size: 20px;
`

export default {
  inject: ['uiSettings'],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  components: {
    FeedShiftReportEventMoleculeStyled,
    ArrowRightIcon,
    ChartWrapper,
    TextWrapper,
    HighlightedText,
    ShiftHealthStatus,
  },
  data() {
    return {
      wasInViewport: false,
    }
  },
  directives: {
    'observe-visibility': ObserveVisibility,
  },
  computed: {
    locale() {
      const ds = this.uiSettings?.dates || 'DE_DE'
      return ds.replace('_', '-').toLowerCase()
    },
    eventTimeFormatted() {
      // TODO: check if 'at' is really and find a way of translating 'at'

      const dt = DateTime.fromISO(this.event?.createdAt)
      if (dt.diffNow().as('days') >= -3) {
        return `${dt.setLocale(this.locale).toRelativeCalendar()} : ${dt
          .setLocale(this.locale)
          .toLocaleString({ ...DateTime.TIME_SIMPLE })}`
      }
      return dt.setLocale(this.locale).toLocaleString({ ...DateTime.DATETIME_SHORT })
    },
    shiftTime() {
      return `${DateTime.fromISO(this.event?.shiftStart).setLocale(this.locale).toLocaleString(DateTime.DATETIME_SHORT)}
      - ${DateTime.fromISO(this.event?.shiftEnd).setLocale(this.locale).toLocaleString(DateTime.DATETIME_SHORT)}`
    },
  },
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.wasInViewport = true
      }
    },
  },
}
</script>
