<template>
  <FeedAssetsStyled>
    <div class="header-wrapper">
      <HeaderStyled>
        <TitleStyled>
          {{ $t('feed.asset.title') }}
          <CircleBadge class="count" :label="myAssets.length" :totalRowCount="assets.length" v-tooltip.bottom-end="tooltip" size="M" />
        </TitleStyled>
        <UtilityAreaStyled>
          <SearchIcon size="1x" @click="toggleSearchTray" />
          <CustomSingleSelect
            class="filter"
            :options="filterOptions"
            @selectedFilter="machineHealth"
            :closeOnSelect="true"
            :customCaretIcon="true"
            :customOptions="'feed.asset.filter.options.'"
            :selectedOption="selectedOption"
            v-if="hasMachineStatusReadPermission"
          />
        </UtilityAreaStyled>
      </HeaderStyled>

      <transition name="fade">
        <SearchTrayStyled v-if="searchTrayExpanded" v-away="away">
          <input v-model="searchQuery" :maxlength="50" :placeholder="$t('feed.asset.search.placeholder')" />
          <XIcon size="1x" @click="searchQuery = ''" />
        </SearchTrayStyled>
      </transition>
    </div>
    <NoDataStyled v-if="myAssets.length === 0 && !$apollo.loading">
      <div v-if="machineHealthFilter || searchQuery">
        {{ $t('feed.asset.search.noAssets') }}
      </div>
      <div v-else>
        {{ $t('feed.asset.noAssets') }}
        <SimplePopover
          :disabled="false"
          :displayActionButtons="true"
          :popoverContent="$t('feed.asset.popover.info')"
          :placement="'top-end'"
          :trigger="'click'"
          :popoverTitle="$t('feed.asset.popover.title')"
          class="wrapper"
        >
          <template v-slot:trigger> <HelpCircleIcon size="1.2x" /> {{ $t('feed.getHelp') }} </template>
          <template v-slot:titleIcon>
            <HelpCircleIcon size="1.2x" />
          </template>
          <template v-slot:closeButton>
            <ButtonSolidStyled>
              {{ $t('actions.ok') }}
            </ButtonSolidStyled>
          </template>
        </SimplePopover>
      </div>
    </NoDataStyled>
    <TalpaLoaderWrapper v-if="$apollo.queries.assets.loading" />
    <WrapperStyled v-else id="assets-sidebar-list">
      <AssetWrapperStyled v-for="asset in myAssets" :key="asset.id" @click="openAsset(asset)">
        <AssetDetailStyled>
          <span class="assetIcon"><TalpaIcon :scope="'AssetTypes'" :name="getIcon(asset.type.name)" /></span>
          <span class="title">{{ asset.name }}</span>
          <LoadingDots class="statusIcon" v-if="$apollo.queries.myAssetsHealth.loading" />
          <span class="statusIcon" v-else-if="asset.machineHealth && hasMachineStatusReadPermission">
            <TalpaIcon :isCustomColor="true" :scope="'HealthStatusLarge'" :name="asset.machineHealth" />
          </span>
        </AssetDetailStyled>
      </AssetWrapperStyled>
      <LoadMoreButtonMolecule v-if="showLoadMore" @load-more-rows="loadMore" class="loadmore" />
      <ScrollToTopButtonMolecule :containerId="'assets-sidebar-list'" :scrollThreshold="800" class="scroll-up" />
    </WrapperStyled>
  </FeedAssetsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { HelpCircleIcon, SearchIcon, XIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'
import get from 'lodash/get'
import { VTooltip } from 'v-tooltip'
import { directive as onClickaway } from 'vue-clickaway'

import {
  TalpaLoaderWrapper,
  TalpaIcon,
  CustomSingleSelect,
  LoadingDots,
  LoadMoreButtonMolecule,
  ScrollToTopButtonMolecule,
} from '@common/components'
import permissionsMixin from '@/mixins/permissions'
import SimplePopover from '@common/components/src/SimplePopover'
import { ButtonSolid } from '@styles/buttons'
import { flexCenter } from '@styles/mixins'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
import { useAssetStore } from '@/stores/assets'

import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import MY_ASSETS_HEALTH_QUERY from '#/graphql/operations/assets/minimals/myAssetsHealth.gql'
import SELECTED_ASSET_FILTER_QUERY from '#/graphql/local/selectedAssetFilter.gql'

const FeedAssetsStyled = styled('div')`
  display: grid;
  grid-gap: 0.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${p => p.theme.colors.solidBG};
  transition: background-color 0.25s ease;
  border-radius: 10px;
  padding: 0.75rem;
  grid-template-rows: min-content minmax(3rem, 1fr);
  .header-wrapper {
    display: grid;
  }
`
const HeaderStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`
const TitleStyled = styled('div')`
  ${flexCenter}
  font-size: 14px;
  text-transform: uppercase;
  padding: 0.25rem 0.35rem;
  opacity: 1;
  color: ${p => chroma(p.theme.colors.title).css()};
  gap: 0.5rem;
  .count {
    cursor: pointer;
  }
`
const NoDataStyled = styled('div')`
  text-align: center;
  font-size: 14px;
  color: ${p => chroma(p.theme.colors.title).css()};
  ${flexCenter}
  .v-popover {
    .trigger {
      ${flexCenter}
      margin: 1rem 0;
      gap: 0.5rem;
      color: ${p => p.theme.colors.primary};
      cursor: pointer;
      &:hover {
        color: ${p => p.theme.colors.primaryActive};
      }
      > svg {
        margin: 0.2rem 0;
      }
    }
  }
`
const AssetWrapperStyled = styled('div')`
  display: flex;
  margin: 0.25rem 0;
  height: 32px;
`
const AssetDetailStyled = styled('div')`
  display: grid;
  cursor: pointer;
  grid-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 2rem 1fr 2rem;
  grid-template-rows: 32px;
  width: 100%;
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 8rem;
    font-size: 14px;
    padding: 0.5rem;
  }
  .assetIcon {
    ${flexCenter}
    padding-left: 0.3rem;
    > svg.icon {
      width: 25px;
      height: 25px;
    }
  }
  .statusIcon {
    margin: 0.2rem;
    justify-self: right;
    > div,
    svg.customColor {
      width: 24px;
      height: 24px;
      path#backDrop {
        fill: ${({ theme }) => theme.colors.atomic.healthStatusIconBG};
      }
    }
  }
  &:hover {
    color: ${p => p.theme.colors.textActive};
    background: ${p => p.theme.colors.atomic.hover};
    border-radius: 5px;
  }
`
const WrapperStyled = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  .loadmore {
    position: relative;
    height: 2rem;
    > button {
      margin: 0;
      height: 2rem;
      min-width: 5rem;
    }
  }
  .scroll-up {
    right: 3.5rem;
  }
`
const UtilityAreaStyled = styled('div')`
  ${flexCenter}
  padding: 0;
  height: 1.8rem;
  gap: 0.35rem;
  > .feather {
    cursor: pointer;
    stroke: ${p => p.theme.colors.primary};
    &:hover {
      stroke: ${p => p.theme.colors.primaryActive};
    }
  }
  .filter {
    .feed_multiselect {
      width: 2rem;
      .caretIcon {
        &:hover {
          stroke: ${props => props.theme.colors.primaryActive};
        }
      }
      .multiselect__select {
        right: 0;
      }
      .multiselect__single {
        display: none;
      }
      .multiselect__content-wrapper {
        left: -13rem;
        top: 2rem;
        width: 15rem;
      }
      .multiselect__option {
        white-space: inherit;
      }
    }
  }
`
const SearchTrayStyled = styled('div')`
  ${flexCenter}
  border-bottom: 2px solid ${p => p.theme.colors.primary};
  margin: 0.5rem 0;
  input {
    border: none;
    outline: none;
    height: 2rem;
    width: 12.5rem;
    background: transparent;
    color: ${p => p.theme.colors.textActivePrimary};
  }
  .feather {
    vertical-align: middle;
    stroke: ${p => p.theme.colors.primary};
    cursor: pointer;
    background: ${p => chroma(p.theme.colors.lightGrey).alpha(0.4).css()};
    border: 2px solid ${p => chroma(p.theme.colors.lightGrey).alpha(0.1).css()};
    border-radius: 50%;
    &:hover {
      stroke: ${p => p.theme.colors.primaryActive};
    }
  }
`

const ButtonSolidStyled = styled(ButtonSolid)`
  padding: 0.5rem 1.5rem;
  color: ${props => props.theme.colors.white};
  background: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  border-color: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
`

export default {
  mixins: [permissionsMixin],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  components: {
    FeedAssetsStyled,
    TitleStyled,
    NoDataStyled,
    AssetWrapperStyled,
    AssetDetailStyled,
    WrapperStyled,
    UtilityAreaStyled,
    HeaderStyled,
    SearchTrayStyled,
    SimplePopover,
    HelpCircleIcon,
    TalpaIcon,
    XIcon,
    SearchIcon,
    TalpaLoaderWrapper,
    CircleBadge,
    CustomSingleSelect,
    ButtonSolidStyled,
    LoadingDots,
    LoadMoreButtonMolecule,
    ScrollToTopButtonMolecule,
  },
  directives: {
    tooltip: VTooltip,
    away: onClickaway,
  },
  data() {
    return {
      assets: [],
      assetsWithHealth: [],
      searchTrayExpanded: false,
      searchQuery: '',
      machineHealthFilter: '',
      selectedOption: {},
      filterOptions: [
        {
          id: 'all',
          label: 'all',
        },
        {
          id: 'warnings',
          label: 'warnings',
        },
        {
          id: 'errors',
          label: 'errors',
        },
      ],
      batchSize: 50, // Number of items to load initially
      loadedBatch: 0,
      totalCount: 0,
      assetsHealthMap: new Map(),
    }
  },
  created() {
    const client = this.$apollo.provider.defaultClient
    const selectedFilter = client.readQuery({
      query: SELECTED_ASSET_FILTER_QUERY,
    })
    const filter = get(selectedFilter, 'selectedAssetFilter', [])
    this.selectedOption = filter.length === 0 ? this.filterOptions[0] : filter
    this.machineHealth(filter)
  },
  computed: {
    tooltip() {
      return {
        content: this.tooltipContent,
        boundariesElement: '#app',
        classes: 'count',
        trigger: 'hover',
      }
    },
    tooltipContent() {
      return `${this.$tc('feed.asset.toolTip')}`
    },
    myAssets() {
      const machineHealthMapped = this.assets.reduce((acc, asset) => {
        const healthStatus = this.assetsHealthMap.get(asset.id)
        acc[asset.id] = healthStatus?.status ?? null
        return acc
      }, {})

      let assetsMapped = this.assets
        .map(asset => ({
          ...asset,
          machineHealth: machineHealthMapped[asset.id],
        }))
        .filter(machine => {
          const searchQueryLower = this.searchQuery.toLowerCase()
          return machine.name?.toLowerCase().includes(searchQueryLower) || machine.type?.name?.toLowerCase().includes(searchQueryLower)
        })
        .filter(machine => {
          const machineHealth = machine.machineHealth ?? 'NONE'
          return machineHealth.toLowerCase().includes(this.machineHealthFilter.toLowerCase())
        })

      this.setCounter(assetsMapped.length)
      return assetsMapped.slice(0, this.batchSize * (this.loadedBatch + 1))
    },
    hasMachineStatusReadPermission() {
      return Boolean(this.machineStatusRead)
    },
    showLoadMore() {
      return this.myAssets.length < this.totalCount
    },
  },
  methods: {
    setCounter(count) {
      this.totalCount = count
    },
    resetBatch() {
      this.loadedBatch = 0
    },
    loadMore() {
      this.loadedBatch++
    },
    getIcon(assetTypeName) {
      return assetTypeName.split(' ').join('')
    },
    openAsset({ id }) {
      this.$router.push({
        name: 'AssetOverview',
        params: {
          id,
        },
      })
    },
    toggleSearchTray() {
      this.searchTrayExpanded = !this.searchTrayExpanded
    },
    machineHealth(selectedFilter) {
      if (selectedFilter.label === 'all') {
        this.machineHealthFilter = ''
      } else if (selectedFilter.label === 'warnings') {
        this.machineHealthFilter = 'Yellow'
      } else if (selectedFilter.label === 'errors') {
        this.machineHealthFilter = 'Red'
      }

      const client = this.$apollo.provider.defaultClient
      client.writeQuery({
        query: SELECTED_ASSET_FILTER_QUERY,
        data: { selectedAssetFilter: selectedFilter },
      })
    },
    away() {
      this.searchQuery = ''
      this.searchTrayExpanded = false
    },
  },
  apollo: {
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result({ data }) {
        this.assetStore.allAssets = data?.assets ?? []
      },
    },
    myAssetsHealth: {
      query: MY_ASSETS_HEALTH_QUERY,
      skip() {
        return !this.assets.length || !this.hasMachineStatusReadPermission
      },
      result({ data }) {
        this.assetsHealthMap = new Map(data.myAssetsHealth.map(asset => [asset.assetId, asset]))
      },
    },
    selectedAssetFilter: {
      query: SELECTED_ASSET_FILTER_QUERY,
    },
  },
}
</script>
