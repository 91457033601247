<template>
  <FeedDashboardStyled>
    <TitleStyled>
      <span>{{ $t('feed.dashboard.title') }}</span>
      <CountCircle :count="dashboards.length" :marginLeft="'.5rem'" v-tooltip.bottom-end="tooltip" class="count" />
    </TitleStyled>
    <TalpaLoaderWrapper v-if="$apollo.loading" />
    <template v-else>
      <NoDataStyled v-if="(dashboards.length === 0 && !$apollo.loading) || !hasDashboardRead">
        <div>
          <span> {{ $t('feed.dashboard.no_dashboards') }} </span>
          <ButtonBorderedStyled
            v-if="hasDashboardWrite"
            @click="$root.$emit('activateOverlay', 'DashboardSettingsOverlay', { mode: 'add' })"
          >
            <PlusIcon /> <span> {{ $t('feed.dashboard.create') }} </span>
          </ButtonBorderedStyled>
          <SimplePopover
            :disabled="false"
            :displayActionButtons="true"
            :popoverContent="$t('feed.dashboard.popover.info')"
            :placement="'top-end'"
            :trigger="'click'"
            :popoverTitle="$t('feed.dashboard.popover.title')"
            class="wrapper"
            v-else
          >
            <template v-slot:trigger> <HelpCircleIcon size="1.2x" /> {{ $t('feed.getHelp') }} </template>
            <template v-slot:titleIcon>
              <HelpCircleIcon size="1.2x" />
            </template>
            <template v-slot:closeButton>
              <ButtonSolidStyled>
                {{ $t('actions.ok') }}
              </ButtonSolidStyled>
            </template>
          </SimplePopover>
        </div>
      </NoDataStyled>
      <WrapperStyled v-if="hasDashboardRead">
        <DashboardListStyled v-for="dashboard in dashboards" :key="dashboard.id" @click="openDashboard(dashboard)">
          <DashboardTitleStyled>
            <TalpaIcon class="icon" :scope="'Feed'" :name="'DashboardIcon'" />
            <span class="title">{{ dashboard.title }}</span>
          </DashboardTitleStyled>
        </DashboardListStyled>
      </WrapperStyled>
    </template>
  </FeedDashboardStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { VTooltip } from 'v-tooltip'
import { PlusIcon, HelpCircleIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'

import { TalpaLoaderWrapper, CountCircle, TalpaIcon } from '@common/components'
import { Base, ButtonSolid } from '@styles/buttons'
import SimplePopover from '@common/components/src/SimplePopover'
import { flexStartCenter, flexCenter } from '@styles/mixins'
import permissionsMixin from '@/mixins/permissions'

import { useAssetStore } from '@/stores/assets'

import DASHBOARDS_MINIMAL_QUERY from '#/graphql/operations/dashboards/dashboardsMinimal.gql'
import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import MY_ASSETS_SITE_QUERY from '#/graphql/operations/assets/minimals/myAssetsSite.gql'
import OEMS_QUERY from '#/graphql/operations/assets/minimals/oems.gql'
import MY_SUBSIDIARIES_WITH_ASSETS_QUERY from '#/graphql/operations/subsidiaries/mySubsidiariesWithAssetsQuery.gql'

const FeedDashboardStyled = styled('div')`
  display: grid;
  grid-gap: 0.25rem;
  background-color: ${p => p.theme.colors.solidBG};
  transition: background-color 0.25s ease;
  border-radius: 10px;
  grid-template-rows: min-content minmax(3rem, 1fr);
  padding: 0.75rem;
`
const TitleStyled = styled('div')`
  ${flexStartCenter}
  font-size: 14px;
  text-transform: uppercase;
  padding: 0.25rem 0.35rem;
  opacity: 1;
  color: ${p => p.theme.colors.title};
  .count {
    cursor: pointer;
  }
  > span {
    white-space: pre;
  }
`
const NoDataStyled = styled('div')`
  text-align: center;
  font-size: 14px;
  color: ${p => p.theme.colors.title};
  display: flex;
  ${flexCenter}
  .v-popover {
    .trigger {
      ${flexCenter}
      margin: 1rem 0;
      gap: 0.5rem;
      color: ${p => p.theme.colors.primary};
      cursor: pointer;
      &:hover {
        color: ${p => p.theme.colors.primaryActive};
      }
      > svg {
        margin: 0.2rem 0;
      }
    }
  }
`
const ButtonBorderedStyled = styled(Base)`
  color: ${props => props.theme.colors.primary};
  margin: auto;
  padding: 1rem;
  font-size: 14px;
  &:hover {
    color: ${props => props.theme.colors.primaryActive};
  }
  > svg {
    margin-right: 0.3rem;
  }
`
const DashboardListStyled = styled('div')`
  display: flex;
  margin: 0.25rem 0;
  height: 30px;
`
const DashboardTitleStyled = styled('div')`
  display: grid;
  cursor: pointer;
  grid-gap: 0.5rem;
  align-items: center;
  grid-template-columns: 2rem 11.5rem;
  padding: 0.2rem;
  &:hover {
    color: ${props => props.theme.colors.textActive};
    background: ${p => p.theme.colors.atomic.hover};
    border-radius: 5px;
  }
  svg.icon {
    width: 24px;
    height: 24px;
  }
  .icon {
    width: 24px;
    height: 24px;
    & > path:nth-of-type(1) {
      fill: transparent;
    }
    & > path:nth-of-type(2) {
      fill: ${({ theme }) => theme.colors.textActive};
    }
    justify-self: center;
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    width: 11rem;
    font-size: 14px;
    padding: 0 0.2rem;
  }
`
const WrapperStyled = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: transparent !important;
  }
`
const ButtonSolidStyled = styled(ButtonSolid)`
  padding: 0.5rem 1.5rem;
  color: ${props => props.theme.colors.white};
  background: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  border-color: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
`

export default {
  mixins: [permissionsMixin],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  components: {
    FeedDashboardStyled,
    TitleStyled,
    TalpaLoaderWrapper,
    NoDataStyled,
    ButtonBorderedStyled,
    WrapperStyled,
    DashboardListStyled,
    DashboardTitleStyled,
    PlusIcon,
    CountCircle,
    TalpaIcon,
    SimplePopover,
    HelpCircleIcon,
    ButtonSolidStyled,
  },
  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {
      dashboards: [],
      queriesLoaded: {
        assets: false,
        myAssetsSite: false,
        oems: false,
        mySubsidiaries: false,
      },
      mySubsidiaries: [],
      assetsSiteMap: new Map(),
      oemsMap: new Map(),
      assets: [],
    }
  },
  watch: {
    allQueriesLoaded(newVal) {
      if (newVal) {
        this.setAssetsWithCategoriesToStore()
      }
    },
  },
  computed: {
    hasDashboardWrite() {
      return Boolean(this.dashboardWrite)
    },
    hasDashboardRead() {
      return Boolean(this.dashboardRead)
    },
    tooltip() {
      return {
        content: this.tooltipContent,
        boundariesElement: '#app',
        classes: 'count',
        trigger: 'hover',
      }
    },
    tooltipContent() {
      return `${this.$tc('feed.dashboard.toolTip')}`
    },
    allQueriesLoaded() {
      // Check if all values in queriesLoaded are true
      return Object.values(this.queriesLoaded).every(Boolean)
    },
    assetsWithUnknownCategories() {
      const availableAssets = this.assets
      const subsidiariesMap = new Map()

      // Pre-compute subsidiaries map
      this.mySubsidiaries?.forEach(sub => {
        sub.assets.forEach(subAsset => {
          if (!subsidiariesMap.has(subAsset.id)) {
            subsidiariesMap.set(subAsset.id, [])
          }
          subsidiariesMap.get(subAsset.id).push({ id: sub.id, name: sub.name })
        })
      })

      // Pre-compute values for unknown entities
      const unknownSite = { id: 'unknown', name: this.$tc('unknownSite', 1), __typename: 'AssetSite' }
      const unknownModel = { id: 'unknown', name: this.$t('unknownModel'), __typename: 'MachineModel' }
      const unknownOEM = { id: 'unknown', name: this.$t('unknownOEM'), alias: this.$t('unknownOEM'), __typename: 'OEM' }
      const unknownType = { id: 'unknown', name: this.$t('unknownType'), __typename: 'MachineType' }
      const unknownSubsidiary = [{ id: 'unknown', name: this.$t('unknownUsergroup'), __typename: 'Subsidiary' }]

      const mappedAssets = availableAssets.map(({ model, type, ...asset }) => {
        const oem = this.oemsMap.get(asset.manufacturerCuid) || unknownOEM
        const site = this.assetsSiteMap.get(asset.id)
          ? { ...this.assetsSiteMap.get(asset.id), id: this.assetsSiteMap.get(asset.id).siteId }
          : unknownSite
        const subsidiaries = subsidiariesMap.get(asset.id) || unknownSubsidiary

        return {
          subsidiary: subsidiaries,
          site,
          model: model || unknownModel,
          oem,
          type: type || unknownType,
          ...asset,
        }
      })

      return mappedAssets
    },
  },
  methods: {
    openDashboard({ id }) {
      this.$router.push({
        name: 'dashboard',
        params: {
          id,
        },
      })
    },
    setAssetsWithCategoriesToStore() {
      const assetsWithUnknownDataFromStore = this.assetStore.getAssetsWithUnknownData()
      if (assetsWithUnknownDataFromStore.length) {
        return
      }
      const mappedAssets = this.assetsWithUnknownCategories
      this.assetStore.setAssetsWithUnknownData(mappedAssets)
    },
  },
  apollo: {
    dashboards: {
      query: DASHBOARDS_MINIMAL_QUERY,
    },
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result({ data }) {
        if (data.assets.length) {
          this.assetStore.allAssets = data?.assets ?? []
        }
        this.queriesLoaded.assets = true
      },
    },
    myAssetsSite: {
      query: MY_ASSETS_SITE_QUERY,
      result({ data }) {
        this.assetsSiteMap = new Map(data.myAssetsSite.map(asset => [asset.assetId, asset]))
        this.queriesLoaded.myAssetsSite = true
      },
    },
    oems: {
      query: OEMS_QUERY,
      result({ data }) {
        this.oemsMap = new Map(data.oems.map(oem => [oem.id, oem]))
        this.queriesLoaded.oems = true
      },
    },
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_WITH_ASSETS_QUERY,
      result() {
        this.queriesLoaded.mySubsidiaries = true
      },
    },
  },
}
</script>
