var render = function () {
  var _vm$assignedAsset$typ, _vm$assignedAsset$oem, _vm$assignedAsset$sit;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MachineAssignmentFeedItemMoleculeStyled', [_c('TextWrapper', [_c('div', {
    staticClass: "event-created"
  }, [_vm._v(_vm._s(_vm.eventTimeFormatted))]), _c('div', [_c('div', [_vm.assignedAsset ? _c('HighlightedText', [_c('div', {
    staticClass: "event-data"
  }, [_vm._v(_vm._s(_vm.$t('MachineAssignmentEvents.titles.banner')))]), _c('span', [_vm._v(" " + _vm._s((_vm$assignedAsset$typ = _vm.assignedAsset.type) === null || _vm$assignedAsset$typ === void 0 ? void 0 : _vm$assignedAsset$typ.name) + ", " + _vm._s(_vm.assignedAsset.name) + " ")]), _vm.assignedAsset.oem ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('MachineAssignmentEvents.titles.of')) + " " + _vm._s((_vm$assignedAsset$oem = _vm.assignedAsset.oem) === null || _vm$assignedAsset$oem === void 0 ? void 0 : _vm$assignedAsset$oem.name) + " ")]) : _vm._e(), _vm.assignedAsset.site ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('MachineAssignmentEvents.titles.in')) + " " + _vm._s((_vm$assignedAsset$sit = _vm.assignedAsset.site) === null || _vm$assignedAsset$sit === void 0 ? void 0 : _vm$assignedAsset$sit.name) + " ")]) : _vm._e()]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('MachineAssignmentEvents.titles.bannerNoData')) + " ")])], 1)]), _c('div', {
    staticClass: "link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'AssetOverview',
        params: {
          id: _vm.assignedAsset.id
        }
      }
    }
  }, [_c('div', {
    staticClass: "link-text"
  }, [_c('ArrowRightIcon'), _vm._v(_vm._s(_vm.$tc('actions.moreDetails')))], 1)])], 1)]), _c('IconStyled', [_vm.iconName ? _c('TalpaIcon', {
    staticClass: "asset-icon",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.iconName
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }