<template>
  <SimplePopover :disabled="!disableAddCard" :trigger="'click'" :placement="'top-end'" :popoverContent="$t('feed.card.warning')">
    <template v-slot:trigger>
      <DropdownSelector
        :dataList="availableAssetDimensions"
        :isDisabled="disableAddCard"
        :totalCount="totalCount"
        :isLoading="$apollo.queries.assetDimensions.loading"
        @selectedValue="addCard"
      />
    </template>
    <template v-slot:icon>
      <HelpCircleIcon size="1.2x" />
    </template>
  </SimplePopover>
</template>

<script>
import { DropdownSelector } from '@common/components'
import CREATE_FEED_CARD from '#/graphql/feed/createFeedCard.gql'
import { getUserIdFromToken } from '@common/utils'
import FEED_CARDS_QUERY from '#/graphql/feed/feedCard.gql'
import SimplePopover from '@common/components/src/SimplePopover'
import { HelpCircleIcon } from 'vue-feather-icons'
import { FlashMessages } from '@common/singletons'

import ASSET_DIMENSIONS_QUERY from '#/graphql/assetDimensions/assetDimensions.gql'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

export default {
  inject: ['uiSettings'],
  components: {
    DropdownSelector,
    SimplePopover,
    HelpCircleIcon,
  },
  data() {
    return {
      assetDimensions: [],
      selectedKPI: {},
      feedCards: [],
    }
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    availableAssetDimensions() {
      if (this.assetDimensions.length < 1) {
        return []
      } else {
        return this.assetDimensions
          .filter(dim => !dim.hasHeatmap)
          .map(d => {
            const isSelected =
              this.feedCards.length > 0 ? !!this.feedCards.filter(f => f !== undefined).find(f => f.assetDimension.id === d.id) : false
            return {
              ...d,
              isSelected,
              label: d?.nameTranslations
                ? getAssetDimensionNameByLocale(d?.nameTranslations, this.locale)
                : d.name.replace(/^./, str => str.toUpperCase()),
            }
          })
      }
    },
    lastPosition() {
      const lastPosition = this.feedCards.reduce((acc, item) => {
        return Math.max(item.position, acc)
      }, 0)
      return lastPosition + 1
    },
    disableAddCard() {
      return this.feedCards.length > 4
    },
    totalCount() {
      return this.availableAssetDimensions.length
    },
  },
  methods: {
    setSelectedKpiOption(o) {
      this.selectedKPI = o
      this.$emit('selectedActivities', this.selectedKPI)
    },

    async addCard(assetDimension) {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_FEED_CARD,
          variables: {
            data: {
              ownerUserId: getUserIdFromToken(this.$keycloak.token),
              position: this.lastPosition,
              assetDimension: {
                connect: {
                  id: assetDimension.id,
                },
              },
            },
          },
          update: (store, { data: { createFeedCard } }) => {
            const data = store.readQuery({
              query: FEED_CARDS_QUERY,
            })
            data.feedCards.push(createFeedCard)
            store.writeQuery({
              query: FEED_CARDS_QUERY,
              data,
            })
          },
          skip() {
            return !assetDimension || this.feedCards.length > 4
          },
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    assetDimensions: {
      query: ASSET_DIMENSIONS_QUERY,
      variables() {
        return {
          where: {
            compatibleWidgets_some: {
              name: 'CARD',
            },
          },
        }
      },
    },
    feedCards: {
      query: FEED_CARDS_QUERY,
    },
  },
}
</script>
