var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedCardStyled', {
    attrs: {
      "tabindex": "-1"
    }
  }, [_c('FeedCardDimesionWidget', {
    attrs: {
      "assetDimension": _vm.assetDimension,
      "selectedTimeframeParam": _vm.selectedTimeframeParam,
      "timeBuckets": _vm.timeBucketsKeys
    }
  }), _c('ButtonStyleless', {
    staticClass: "delete-button",
    on: {
      "click": _vm.deleteCard
    }
  }, [_c('Trash2Icon')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }