var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedShiftReportEventMoleculeStyled', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.visibilityChanged,
      expression: "visibilityChanged"
    }]
  }, [_c('TextWrapper', [_c('div', {
    staticClass: "event-created"
  }, [_vm._v(_vm._s(_vm.eventTimeFormatted))]), _c('div', {
    staticClass: "event-data"
  }, [_c('div', [_c('HighlightedText', [_vm._v(_vm._s(_vm.event.shiftName + ' ' + _vm.$t('feed.insightsAvailable')))])], 1), _c('div', [_vm._v(_vm._s(_vm.event.recipient.name))]), _c('div', [_vm._v(_vm._s(_vm.shiftTime))])]), _c('div', {
    staticClass: "link"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'ShiftInsights',
        params: {
          id: _vm.event.id
        }
      }
    }
  }, [_c('div', {
    staticClass: "link-text"
  }, [_c('ArrowRightIcon'), _vm._v(_vm._s(_vm.$tc('actions.moreDetails')))], 1)])], 1)]), _c('ChartWrapper', [_c('ShiftHealthStatus', {
    attrs: {
      "eventId": _vm.event.id,
      "shouldLoad": _vm.wasInViewport
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }