var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedAssetsStyled', [_c('div', {
    staticClass: "header-wrapper"
  }, [_c('HeaderStyled', [_c('TitleStyled', [_vm._v(" " + _vm._s(_vm.$t('feed.asset.title')) + " "), _c('CircleBadge', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.bottom-end",
      value: _vm.tooltip,
      expression: "tooltip",
      modifiers: {
        "bottom-end": true
      }
    }],
    staticClass: "count",
    attrs: {
      "label": _vm.myAssets.length,
      "totalRowCount": _vm.assets.length,
      "size": "M"
    }
  })], 1), _c('UtilityAreaStyled', [_c('SearchIcon', {
    attrs: {
      "size": "1x"
    },
    on: {
      "click": _vm.toggleSearchTray
    }
  }), _vm.hasMachineStatusReadPermission ? _c('CustomSingleSelect', {
    staticClass: "filter",
    attrs: {
      "options": _vm.filterOptions,
      "closeOnSelect": true,
      "customCaretIcon": true,
      "customOptions": 'feed.asset.filter.options.',
      "selectedOption": _vm.selectedOption
    },
    on: {
      "selectedFilter": _vm.machineHealth
    }
  }) : _vm._e()], 1)], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.searchTrayExpanded ? _c('SearchTrayStyled', {
    directives: [{
      name: "away",
      rawName: "v-away",
      value: _vm.away,
      expression: "away"
    }]
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    attrs: {
      "maxlength": 50,
      "placeholder": _vm.$t('feed.asset.search.placeholder')
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  }), _c('XIcon', {
    attrs: {
      "size": "1x"
    },
    on: {
      "click": function click($event) {
        _vm.searchQuery = '';
      }
    }
  })], 1) : _vm._e()], 1)], 1), _vm.myAssets.length === 0 && !_vm.$apollo.loading ? _c('NoDataStyled', [_vm.machineHealthFilter || _vm.searchQuery ? _c('div', [_vm._v(" " + _vm._s(_vm.$t('feed.asset.search.noAssets')) + " ")]) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('feed.asset.noAssets')) + " "), _c('SimplePopover', {
    staticClass: "wrapper",
    attrs: {
      "disabled": false,
      "displayActionButtons": true,
      "popoverContent": _vm.$t('feed.asset.popover.info'),
      "placement": 'top-end',
      "trigger": 'click',
      "popoverTitle": _vm.$t('feed.asset.popover.title')
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('feed.getHelp')) + " ")];
      },
      proxy: true
    }, {
      key: "titleIcon",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }, {
      key: "closeButton",
      fn: function fn() {
        return [_c('ButtonSolidStyled', [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")])];
      },
      proxy: true
    }], null, false, 1046043721)
  })], 1)]) : _vm._e(), _vm.$apollo.queries.assets.loading ? _c('TalpaLoaderWrapper') : _c('WrapperStyled', {
    attrs: {
      "id": "assets-sidebar-list"
    }
  }, [_vm._l(_vm.myAssets, function (asset) {
    return _c('AssetWrapperStyled', {
      key: asset.id,
      on: {
        "click": function click($event) {
          return _vm.openAsset(asset);
        }
      }
    }, [_c('AssetDetailStyled', [_c('span', {
      staticClass: "assetIcon"
    }, [_c('TalpaIcon', {
      attrs: {
        "scope": 'AssetTypes',
        "name": _vm.getIcon(asset.type.name)
      }
    })], 1), _c('span', {
      staticClass: "title"
    }, [_vm._v(_vm._s(asset.name))]), _vm.$apollo.queries.myAssetsHealth.loading ? _c('LoadingDots', {
      staticClass: "statusIcon"
    }) : asset.machineHealth && _vm.hasMachineStatusReadPermission ? _c('span', {
      staticClass: "statusIcon"
    }, [_c('TalpaIcon', {
      attrs: {
        "isCustomColor": true,
        "scope": 'HealthStatusLarge',
        "name": asset.machineHealth
      }
    })], 1) : _vm._e()], 1)], 1);
  }), _vm.showLoadMore ? _c('LoadMoreButtonMolecule', {
    staticClass: "loadmore",
    on: {
      "load-more-rows": _vm.loadMore
    }
  }) : _vm._e(), _c('ScrollToTopButtonMolecule', {
    staticClass: "scroll-up",
    attrs: {
      "containerId": 'assets-sidebar-list',
      "scrollThreshold": 800
    }
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }