var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedDashboardStyled', [_c('TitleStyled', [_c('span', [_vm._v(_vm._s(_vm.$t('feed.dashboard.title')))]), _c('CountCircle', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.bottom-end",
      value: _vm.tooltip,
      expression: "tooltip",
      modifiers: {
        "bottom-end": true
      }
    }],
    staticClass: "count",
    attrs: {
      "count": _vm.dashboards.length,
      "marginLeft": '.5rem'
    }
  })], 1), _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : [_vm.dashboards.length === 0 && !_vm.$apollo.loading || !_vm.hasDashboardRead ? _c('NoDataStyled', [_c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('feed.dashboard.no_dashboards')) + " ")]), _vm.hasDashboardWrite ? _c('ButtonBorderedStyled', {
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('activateOverlay', 'DashboardSettingsOverlay', {
          mode: 'add'
        });
      }
    }
  }, [_c('PlusIcon'), _vm._v(" "), _c('span', [_vm._v(" " + _vm._s(_vm.$t('feed.dashboard.create')) + " ")])], 1) : _c('SimplePopover', {
    staticClass: "wrapper",
    attrs: {
      "disabled": false,
      "displayActionButtons": true,
      "popoverContent": _vm.$t('feed.dashboard.popover.info'),
      "placement": 'top-end',
      "trigger": 'click',
      "popoverTitle": _vm.$t('feed.dashboard.popover.title')
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        }), _vm._v(" " + _vm._s(_vm.$t('feed.getHelp')) + " ")];
      },
      proxy: true
    }, {
      key: "titleIcon",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }, {
      key: "closeButton",
      fn: function fn() {
        return [_c('ButtonSolidStyled', [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")])];
      },
      proxy: true
    }], null, false, 1046043721)
  })], 1)]) : _vm._e(), _vm.hasDashboardRead ? _c('WrapperStyled', _vm._l(_vm.dashboards, function (dashboard) {
    return _c('DashboardListStyled', {
      key: dashboard.id,
      on: {
        "click": function click($event) {
          return _vm.openDashboard(dashboard);
        }
      }
    }, [_c('DashboardTitleStyled', [_c('TalpaIcon', {
      staticClass: "icon",
      attrs: {
        "scope": 'Feed',
        "name": 'DashboardIcon'
      }
    }), _c('span', {
      staticClass: "title"
    }, [_vm._v(_vm._s(dashboard.title))])], 1)], 1);
  }), 1) : _vm._e()]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }