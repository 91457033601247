var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CardAreaHeaderStyled', [_c('LeftPanelStyled', [_c('div', {
    staticClass: "header"
  }, [_vm._v(_vm._s(_vm.$t('feed.card.title')))]), _c('div', {
    staticClass: "card"
  }, [_vm._v(_vm._s(_vm.feedCards.length) + "/5 " + _vm._s(_vm.$t('feed.card.cards')))])]), _c('RightPanelStyled', [_c('CardTimePicker'), _c('AssetDimensionPicker')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }