var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('InfoCardStyled', [_c('div', {
    staticClass: "wrapper"
  }, [_c('img', {
    attrs: {
      "src": './stayTunedIcon.svg'
    }
  })]), _c('div', {
    staticClass: "info"
  }, [_vm._v(_vm._s(_vm.$t('feed.stayTunedInfo')))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }