var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AddCardStyled', [_c('addCardIcon', {
    attrs: {
      "size": "2.5x"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }