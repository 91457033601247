<template>
  <FeedCardStyled tabindex="-1">
    <FeedCardDimesionWidget
      :assetDimension="assetDimension"
      :selectedTimeframeParam="selectedTimeframeParam"
      :timeBuckets="timeBucketsKeys"
    />
    <ButtonStyleless class="delete-button" @click="deleteCard">
      <Trash2Icon />
    </ButtonStyleless>
  </FeedCardStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { get } from 'lodash'
import anime from 'animejs/lib/anime.es.js'
import { DateTime, Interval } from 'luxon'
import { Trash2Icon } from 'vue-feather-icons'
import { FlashMessages } from '@common/singletons'

import { getTimeBuckets } from '@common/utils'
import FeedCardDimesionWidget from './FeedCardDimesionWidget'
import ButtonStyleless from '@/components/Atomic/Atoms/ButtonStyleless'

import DELETE_FEED_CARD from '#/graphql/feed/deleteFeedCard.gql'
import FEED_CARDS_QUERY from '#/graphql/feed/feedCard.gql'
import FEED_CARD_SELECTED_TIME_QUERY from '#/graphql/local/feedCardSelectedTime.gql'

const FeedCardStyled = styled('div')`
  position: relative;
  background: ${({ theme }) => theme.colors.widgetBG};
  box-shadow: 0px 3px 6px #00000029;
  width: 15rem;
  height: 15rem;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.widgetBG};
  transform-style: preserve-3d;
  .delete-button {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    transform: translateX(-50%);
    opacity: 0;
    transition: 0.5s transform, 0.5s opacity;
    transition-delay: 0.5s;
  }
  &:focus,
  &:hover {
    outline: none;
    .delete-button {
      transform: translateX(0);
      transition-delay: 0s;
      opacity: 1;
    }
  }
`

export default {
  props: {
    cardData: {
      type: Object,
      required: true,
    },
  },
  components: {
    FeedCardDimesionWidget,
    FeedCardStyled,
    ButtonStyleless,
    Trash2Icon,
  },
  data() {
    return {
      selectedTimePeriod: [],
      timeframe: null,
    }
  },

  computed: {
    assetDimension() {
      return this.cardData.assetDimension
    },
    granularity() {
      const interval = Interval.fromISO(this.feedCardSelectedTime[0])
      return interval.length('days') > 2 ? 'P1D' : 'PT1H'
    },
    selectedTimeframeInterval() {
      const { interval } = this.feedCardSelectedTime.map(tf => ({
        interval: Interval.fromISO(tf),
      }))[0]
      return interval
    },
    selectedTimeframeParam() {
      const interval = this.selectedTimeframeInterval
      const granularity = this.granularity
      const start = interval.start.toUTC().toISO()
      const end = interval.end.toUTC().toISO()
      return {
        start,
        end,
        timezone: DateTime.local().zoneName,
        granularity,
        shifts: [],
      }
    },
    timeBucketsKeys() {
      return getTimeBuckets(this.selectedTimeframeInterval, this.granularity)
    },
  },
  methods: {
    async deleteCard() {
      const elem = this.$el
      const animation = anime({
        targets: [elem],
        opacity: [1, 0],
        rotateY: [0, 90],
        duration: 500,
        easing: 'easeInOutQuad',
      })
      await animation.finished

      try {
        await this.$apollo.mutate({
          mutation: DELETE_FEED_CARD,
          variables: {
            where: {
              id: this.cardData.id,
            },
          },
          update: async (store, { data: { deleteFeedCard } }) => {
            const data = store.readQuery({
              query: FEED_CARDS_QUERY,
            })
            data.feedCards = data.feedCards.filter(d => d.id !== deleteFeedCard.id)
            store.writeQuery({
              query: FEED_CARDS_QUERY,
              data,
            })
          },
          skip() {
            return !get(this.cardData, 'id')
          },
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
  },
  apollo: {
    feedCardSelectedTime: {
      query: FEED_CARD_SELECTED_TIME_QUERY,
      update(res) {
        return [res.feedCardSelectedTime]
      },
    },
  },
}
</script>
