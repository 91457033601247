<template>
  <InfoCardStyled>
    <div class="wrapper">
      <img :src="'./stayTunedIcon.svg'" />
    </div>
    <div class="info">{{ $t('feed.stayTunedInfo') }}</div>
  </InfoCardStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexColumns } from '@styles/mixins'

const InfoCardStyled = styled('div')`
  ${flexColumns}
  padding: 1rem;
  width: 100%;
  .wrapper {
    width: 100%;
    align-self: center;
  }
  .info {
    width: 100%;
    text-align: center;
    align-self: center;
    word-break: break-word;
    color: ${p => p.theme.colors.title};
  }
  @media (min-width: 768px) {
    .wrapper {
      width: 500px;
    }
    .info {
      width: 60%;
      font-size: 18px;
    }
  }
`
export default {
  inject: ['theme'],
  components: {
    InfoCardStyled,
  },
}
</script>
