<template>
  <NoMoreMessageWrapper>
    <NoMoreUpSection @click="$emit('handleGoUp')">
      <GoUpButton />
      {{ $t('actions.scrollToTop') }}
    </NoMoreUpSection>
    <NoOtherFeedMessage>
      {{ $t('messages.noAdditionalData') }}
    </NoOtherFeedMessage>
  </NoMoreMessageWrapper>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import GoUpButton from '../Atoms/GoUpButton'

const NoMoreMessageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
`

const NoMoreUpSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.atomic.textMain};
  margin-bottom: 10px;
`

const NoOtherFeedMessage = styled('div')`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.atomic.osloGrey};
`

export default {
  components: {
    NoMoreMessageWrapper,
    NoMoreUpSection,
    NoOtherFeedMessage,
    GoUpButton,
  },
}
</script>
