var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ShiftHealthStatusStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('feed.healthStatus')))]), _c('HealthCountsStyled', [_c('HealthCountStyled', [_c('TalpaIcon', {
    attrs: {
      "isCustomColor": true,
      "scope": 'HealthStatusLarge',
      "name": 'Red'
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('div', [_vm._v(_vm._s(_vm.redMachineCount))])], 1), _c('HealthCountStyled', [_c('TalpaIcon', {
    attrs: {
      "isCustomColor": true,
      "scope": 'HealthStatusLarge',
      "name": 'Yellow'
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('div', [_vm._v(_vm._s(_vm.yellowMachineCount))])], 1), _c('HealthCountStyled', [_c('TalpaIcon', {
    attrs: {
      "isCustomColor": true,
      "scope": 'HealthStatusLarge',
      "name": 'Green'
    }
  }), _vm.isLoading ? _c('LoadingDots') : _c('div', [_vm._v(_vm._s(_vm.greenMachineCount))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }