<template>
  <PerformanceTemplateStyled>
    <CardAreaHeader :feedCards="feedCards" />
    <CardAreaMain :feedCards="feedCards" />
  </PerformanceTemplateStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import CardAreaHeader from './PerformanceTemplate/CardAreaHeader'
import CardAreaMain from './PerformanceTemplate/CardAreaMain'
import FEED_CARDS_QUERY from '#/graphql/feed/feedCard.gql'

const PerformanceTemplateStyled = styled('div')`
  grid-area: performance;
  overflow-x: auto;
  padding: 0 0.5rem;
`

export default {
  components: {
    PerformanceTemplateStyled,
    CardAreaHeader,
    CardAreaMain,
  },
  data() {
    return {
      feedCards: [],
    }
  },
  apollo: {
    feedCards: {
      query: FEED_CARDS_QUERY,
    },
  },
}
</script>
