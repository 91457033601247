<template>
  <CardAreaMainStyled>
    <div class="feed-card-grid feed-card-slots">
      <FeedCardSlot v-for="i in 5" :key="i" />
    </div>
    <transition-group tag="div" name="feed-card-animation" class="feed-card-grid feed-cards" v-on:enter="enter">
      <template v-for="(n, i) in 5">
        <FeedCard v-if="feedCards[i]" :key="feedCards[i].id" :cardData="feedCards[i]" class="feed-card" />
        <div class="empty-slot" v-else @click="initiateFlashing" :key="i" />
      </template>
    </transition-group>
  </CardAreaMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import anime from 'animejs/lib/anime.es.js'

import FeedCardSlot from './Main/FeedCardSlot'
import FeedCard from './Main/FeedCard'

const CardAreaMainStyled = styled('div')`
  position: relative;
  overflow-x: auto;

  .feed-card-grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(15rem, 1fr));
    grid-template-rows: 15rem;
    grid-gap: 1.5rem;
    margin-bottom: 10px;
    justify-items: center;
    &.feed-cards {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  .empty-slot {
    width: 100%;
    height: 100%;
  }

  .feed-card-animation-move {
    transition: transform 1s;
  }

  .feed-card-animation-leave-active {
    position: absolute;
  }
`

export default {
  props: {
    feedCards: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardAreaMainStyled,
    FeedCardSlot,
    FeedCard,
  },
  methods: {
    async enter(el, done) {
      const animation = anime({
        targets: [el],
        scale: [0.5, 1],
        duration: 100,
        easing: 'easeInOutQuad',
      })
      await animation.finished
      done()
    },
    initiateFlashing() {
      this.$root.$emit('flashing-initiate', true)
    },
  },
}
</script>
