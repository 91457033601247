<template>
  <CardTimePickerStyled>
    <CustomSingleSelect
      :options="availableTimeRange"
      :customOptions="'feed.card.options.'"
      :customLabels="'feed.card.options.'"
      :filter="timeSelected"
      :closeOnSelect="true"
      @selectedFilter="updateTimeSelection"
      :selectedOption="preselectedOption"
      class="select"
    >
      <template v-slot:customLabelIcon> <CalendarIcon size="1.2x" class="icon" /> </template>
    </CustomSingleSelect>
  </CardTimePickerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CustomSingleSelect } from '@common/components'
import { CalendarIcon } from 'vue-feather-icons'
import { DateTime, Interval } from 'luxon'

import FEED_CARD_SELECTED_TIME_QUERY from '#/graphql/local/feedCardSelectedTime.gql'

const CardTimePickerStyled = styled('div')`
  margin-right: 0.5rem;
  border-radius: 8px;

  .feed_multiselect {
    width: 5rem;
    .multiselect__single {
      color: ${p => p.theme.colors.normalFontColor} !important;
      .labelText {
        display: none;
      }
    }
    .multiselect__content-wrapper {
      width: 10.5rem;
      left: -6rem;
    }
    @media (min-width: 768px) {
      width: 13.5rem !important;
      .labelText {
        display: inline-block !important;
      }
      .multiselect__content-wrapper {
        width: 13.5rem;
        left: 0;
      }
    }
  }
  .select {
    border-radius: 8px;
    background: ${({ theme }) => (theme.isDark ? theme.colors.talpaGrey : theme.colors.white)};
  }
`

export default {
  components: {
    CardTimePickerStyled,
    CustomSingleSelect,
    CalendarIcon,
  },
  data() {
    return {
      preselectedOption: {},
      timeSelected: null,
      availableTimeRange: [
        {
          id: '1',
          label: 'lastDay',
        },
        {
          id: '2',
          label: 'lastWeek',
        },
        {
          id: '3',
          label: 'lastMonth',
        },
      ],
    }
  },
  created() {
    if (!this.timeSelected) {
      this.timeSelected = this.updateTimeSelection(this.availableTimeRange[0])
      this.preselectedOption = this.availableTimeRange[0]
    }
  },
  methods: {
    updateTimeSelection(args) {
      let dateInterval
      if (args.id === '1') {
        dateInterval = Interval.fromDateTimes(
          DateTime.local().minus({ hours: 24 }).startOf('hour'),
          DateTime.local().startOf('hour'),
        ).toISO()
      }
      if (args.id === '2') {
        dateInterval = Interval.fromDateTimes(DateTime.local().minus({ day: 6 }).startOf('day'), DateTime.local().endOf('day')).toISO()
      }
      if (args.id === '3') {
        dateInterval = Interval.fromDateTimes(DateTime.local().minus({ day: 29 }).startOf('day'), DateTime.local().endOf('day')).toISO()
      }
      const client = this.$apollo.provider.defaultClient
      client.writeQuery({
        query: FEED_CARD_SELECTED_TIME_QUERY,
        data: { feedCardSelectedTime: dateInterval },
      })
    },
  },
}
</script>
