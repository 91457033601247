var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedOrganizationStyled', [!_vm.$apollo.queries.myMemberships.loading ? _c('FeedHeaderMolecule', {
    attrs: {
      "subsidiaryMemberships": _vm.subsidiaryMemberships
    },
    on: {
      "handleCategoryFilterChange": _vm.handleCategoryFilterChange,
      "handleTimeFilterChange": _vm.handleTimeFilterChange,
      "handleSubsidiaryFilterChange": _vm.handleSubsidiaryFilterChange
    }
  }) : _vm._e(), _vm.$apollo.loading ? _c('TalpaLoaderWrapper') : [_vm.myEventsFiltered.length === 0 ? _c('StayTunedInfoCard', {
    staticClass: "infoCard"
  }) : [_c('EventListStyled', [_vm._l(_vm.myEventsFiltered, function (event) {
    return [event.type === 'SHIFT_REPORT' ? _c('FeedShiftReportEventMolecule', {
      key: event.id,
      attrs: {
        "event": event
      }
    }) : _vm._e(), event.type === 'ASSET_ASSIGNMENT' ? _c('MachineAssignmentFeedItemMolecule', {
      key: event.id,
      attrs: {
        "event": event
      }
    }) : _vm._e(), event.type === 'PERFORMANCE_GOAL_RESULT' && _vm.hasPlanningAppPermission ? _c('PerformanceGoalFeedItem', {
      key: event.id,
      attrs: {
        "event": event
      }
    }) : _vm._e()];
  })], 2), _c('InfiniteLoading', {
    attrs: {
      "identifier": _vm.infiniteId
    },
    on: {
      "infinite": _vm.infiniteHandler
    }
  }, [_c('template', {
    slot: "no-results"
  }, [_c('FeedListNoMoreMessage', {
    on: {
      "handleGoUp": _vm.handleScrollToTop
    }
  })], 1), _c('template', {
    slot: "no-more"
  }, [_c('FeedListNoMoreMessage', {
    on: {
      "handleGoUp": _vm.handleScrollToTop
    }
  })], 1)], 2)]]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }