var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FeedHeaderWrapper', [_c('FeedHeaderStyled', [_c('div', {
    staticClass: "filterWrapper",
    on: {
      "click": function click($event) {
        return _vm.toggleFilterTray();
      }
    }
  }, [_c('TalpaIcon', {
    staticClass: "filterIcon",
    attrs: {
      "scope": 'Feed',
      "name": 'FilterIcon'
    }
  })], 1), _c('CategoryWrapperStyled', [_vm._l(_vm.categories, function (category) {
    return _c('CategoryListStyled', {
      key: category.id,
      on: {
        "click": function click($event) {
          return _vm.selectedCategories(category);
        }
      }
    }, [_c('CategoryStyled', {
      class: {
        selected: category.isSelected
      }
    }, [_c('TalpaIcon', {
      staticClass: "icon",
      attrs: {
        "scope": 'Feed',
        "name": "".concat(category.iconName)
      }
    }), _vm._v(" " + _vm._s(_vm.$t('feed.category.' + category.label)) + " ")], 1)], 1);
  }), _c('CustomSingleSelect', {
    staticClass: "categorySelect",
    attrs: {
      "selectedOption": _vm.selectedOption,
      "options": _vm.categories,
      "shouldNotResetSelectedOption": true,
      "customOptions": 'feed.category.',
      "customLabels": 'feed.category.',
      "categoryIcon": true,
      "closeOnSelect": true
    },
    on: {
      "selectedFilter": _vm.selectedCategories
    }
  })], 2)], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isTrayExpanded ? _c('FilterTrayStyled', [_c('FilterStyled', [_c('span', [_vm._v(_vm._s(_vm.$t('feed.filter.title')) + ":")]), _c('CustomSingleSelect', {
    staticClass: "categorySelect",
    attrs: {
      "selectedOption": _vm.selectedTimeFilterOption,
      "options": _vm.filterOptions,
      "shouldNotResetSelectedOption": true,
      "customOptions": 'feed.filter.options.',
      "customLabels": 'feed.filter.label.',
      "closeOnSelect": true
    },
    on: {
      "selectedFilter": _vm.selectTimeFilter
    }
  }), _vm.subsidiaryMembershipOptions.length > 0 ? _c('span', [_vm._v(_vm._s(_vm.$t('feed.subsidiaries')) + ": ")]) : _vm._e(), _vm.subsidiaryMembershipOptions.length > 0 ? _c('CustomSingleSelect', {
    staticClass: "categorySelect",
    attrs: {
      "selectedOption": _vm.selectedOptionTranslated,
      "options": _vm.subsidiaryMembershipOptions,
      "shouldNotResetSelectedOption": true,
      "closeOnSelect": true
    },
    on: {
      "selectedFilter": _vm.selectSubsidiaryFilter
    }
  }) : _vm._e()], 1), _c('ClearStyled', [_c('span', {
    staticClass: "reset",
    on: {
      "click": function click($event) {
        return _vm.resetFilter();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('feed.filter.clear')))]), _vm._v(" | "), _c('XIcon', {
    attrs: {
      "size": "1.5x"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleFilterTray();
      }
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }