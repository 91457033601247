var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CardAreaMainStyled', [_c('div', {
    staticClass: "feed-card-grid feed-card-slots"
  }, _vm._l(5, function (i) {
    return _c('FeedCardSlot', {
      key: i
    });
  }), 1), _c('transition-group', {
    staticClass: "feed-card-grid feed-cards",
    attrs: {
      "tag": "div",
      "name": "feed-card-animation"
    },
    on: {
      "enter": _vm.enter
    }
  }, [_vm._l(5, function (n, i) {
    return [_vm.feedCards[i] ? _c('FeedCard', {
      key: _vm.feedCards[i].id,
      staticClass: "feed-card",
      attrs: {
        "cardData": _vm.feedCards[i]
      }
    }) : _c('div', {
      key: i,
      staticClass: "empty-slot",
      on: {
        "click": _vm.initiateFlashing
      }
    })];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }