var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NoMoreMessageWrapper', [_c('NoMoreUpSection', {
    on: {
      "click": function click($event) {
        return _vm.$emit('handleGoUp');
      }
    }
  }, [_c('GoUpButton'), _vm._v(" " + _vm._s(_vm.$t('actions.scrollToTop')) + " ")], 1), _c('NoOtherFeedMessage', [_vm._v(" " + _vm._s(_vm.$t('messages.noAdditionalData')) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }