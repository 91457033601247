var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PerformanceTemplateStyled', [_c('CardAreaHeader', {
    attrs: {
      "feedCards": _vm.feedCards
    }
  }), _c('CardAreaMain', {
    attrs: {
      "feedCards": _vm.feedCards
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }