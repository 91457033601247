var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CardTimePickerStyled', [_c('CustomSingleSelect', {
    staticClass: "select",
    attrs: {
      "options": _vm.availableTimeRange,
      "customOptions": 'feed.card.options.',
      "customLabels": 'feed.card.options.',
      "filter": _vm.timeSelected,
      "closeOnSelect": true,
      "selectedOption": _vm.preselectedOption
    },
    on: {
      "selectedFilter": _vm.updateTimeSelection
    },
    scopedSlots: _vm._u([{
      key: "customLabelIcon",
      fn: function fn() {
        return [_c('CalendarIcon', {
          staticClass: "icon",
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }