var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SimplePopover', {
    attrs: {
      "disabled": !_vm.disableAddCard,
      "trigger": 'click',
      "placement": 'top-end',
      "popoverContent": _vm.$t('feed.card.warning')
    },
    scopedSlots: _vm._u([{
      key: "trigger",
      fn: function fn() {
        return [_c('DropdownSelector', {
          attrs: {
            "dataList": _vm.availableAssetDimensions,
            "isDisabled": _vm.disableAddCard,
            "totalCount": _vm.totalCount,
            "isLoading": _vm.$apollo.queries.assetDimensions.loading
          },
          on: {
            "selectedValue": _vm.addCard
          }
        })];
      },
      proxy: true
    }, {
      key: "icon",
      fn: function fn() {
        return [_c('HelpCircleIcon', {
          attrs: {
            "size": "1.2x"
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }