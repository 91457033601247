<template>
  <ShiftHealthStatusStyled>
    <div class="title">{{ $t('feed.healthStatus') }}</div>
    <HealthCountsStyled>
      <HealthCountStyled>
        <TalpaIcon :isCustomColor="true" :scope="'HealthStatusLarge'" :name="'Red'" />
        <LoadingDots v-if="isLoading" />
        <div v-else>{{ redMachineCount }}</div>
      </HealthCountStyled>
      <HealthCountStyled>
        <TalpaIcon :isCustomColor="true" :scope="'HealthStatusLarge'" :name="'Yellow'" />
        <LoadingDots v-if="isLoading" />
        <div v-else>{{ yellowMachineCount }}</div>
      </HealthCountStyled>
      <HealthCountStyled>
        <TalpaIcon :isCustomColor="true" :scope="'HealthStatusLarge'" :name="'Green'" />
        <LoadingDots v-if="isLoading" />
        <div v-else>{{ greenMachineCount }}</div>
      </HealthCountStyled>
    </HealthCountsStyled>
  </ShiftHealthStatusStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon } from '@common/components'
import { LoadingDots } from '@common/components'

import MY_EVENT_QUERY from '#/graphql/feed/myEvent.gql'

const ShiftHealthStatusStyled = styled('div')`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40% 60%;
  grid-template-areas:
    'text'
    'chart';
  width: 168px;
  height: 161px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.atomic.feedListCard};
  .title {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const HealthCountsStyled = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  font-size: 16px;
  align-items: center;
  padding: 0px 10px;
`
const HealthCountStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div {
    padding-bottom: 8px;
    svg.customColor {
      width: 25px;
      height: 25px;
      path#backDrop {
        fill: ${({ theme }) => theme.colors.atomic.healthStatusIconBG};
      }
    }
  }
`

export default {
  props: {
    eventId: {
      type: String,
      required: true,
    },
    shouldLoad: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ShiftHealthStatusStyled,
    HealthCountsStyled,
    HealthCountStyled,
    TalpaIcon,
    LoadingDots,
  },
  data() {
    return {
      myEvent: {},
    }
  },
  computed: {
    didLoad() {
      return this.shouldLoad && !this.isLoading
    },
    isLoading() {
      return this.$apollo.queries.myEvent.loading
    },
    assetsByHealthStatus() {
      const assets = this.myEvent?.assets || []
      const assetHealth = this.myEvent?.assetHealth || []
      return assets.reduce(
        (acc, asset) => {
          if (assetHealth.some(health => health.status === 'Red' && health.assetId === asset.id)) {
            acc.red.push(asset)
          } else if (assetHealth.some(health => health.status === 'Yellow' && health.assetId === asset.id)) {
            acc.yellow.push(asset)
          } else if (assetHealth.some(health => health.status === 'Green' && health.assetId === asset.id)) {
            acc.green.push(asset)
          }
          return acc
        },
        {
          red: [],
          yellow: [],
          green: [],
        },
      )
    },
    redMachineCount() {
      return this.assetsByHealthStatus?.red?.length
    },
    yellowMachineCount() {
      return this.assetsByHealthStatus?.yellow?.length
    },
    greenMachineCount() {
      return this.assetsByHealthStatus?.green?.length
    },
  },
  apollo: {
    myEvent: {
      query: MY_EVENT_QUERY,
      variables() {
        return {
          where: {
            id: this.eventId,
          },
        }
      },
      skip() {
        return !this.shouldLoad
      },
    },
  },
}
</script>
